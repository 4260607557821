<template>
  <HourglassLoader :loading="loading">
    <div class="card bg-dark shadow-sm">
      <div class="card-header mb-3 text-white">
        <div class="d-block">
          <div class="d-flex justify-content-between w-100">
            <div class="h5 font-weight-bolder mb-2">Metrics</div>
            <div class="d-flex flex-column flex-md-row ml-auto">
              <a class="btn btn-secondary text-dark btn-sm mr-2" @click="RefreshBalance">
                <span class="fas fa-sync"></span>
                Refresh
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- Filter metrics by date -->
        <div class="row align-items-center mb-3">
          <div class="col-md-4 mb-2">
            <label class="form-label">From:</label>
            <input type="date" class="form-control" v-model="fromDate" />
          </div>
          <div class="col-md-4 mb-2">
            <label class="form-label">To:</label>
            <input type="date" class="form-control" v-model="toDate" />
          </div>
          <div class="col-md-4">
            <label class="form-label">Action:</label>

            <div class="d-grid gap-2 d-md-block">
              <button
                class="btn btn-primary me-2"
                @click="RefreshBalance"
                :disabled="!fromDate && !toDate"
              >Apply</button>
              <button
                class="btn btn-secondary"
                @click="clearFilter"
                :disabled="!fromDate && !toDate"
              >Clear</button>
            </div>
          </div>
        </div>
        <!-- <div class="input-group mb-3">
          <span class="input-group-text">From:</span>
          <input type="date" class="form-control" v-model="fromDate" />
          <span class="input-group-text">To:</span>
          <input type="date" class="form-control" v-model="toDate" />
          <button class="btn btn-primary" @click="applyFilter">Apply</button>
          <button class="btn btn-secondary" @click="clearFilter">Clear</button>
        </div>-->
        <ul class="nav nav-tabs mb-2">
          <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'count-payout' ? 'bg-primary text-white' : '']"
              @click="selectTab('count-payout')"
            >Count payout</button>
          </li>
          <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'sum-payout' ? 'bg-primary text-white' : '']"
              @click="selectTab('sum-payout')"
            >Sum Payout</button>
          </li>
          <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'count-withdrawal' ? 'bg-primary text-white' : '']"
              @click="selectTab('count-withdrawal')"
            >Count withdrawal</button>
          </li>

          <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'sum-withdrawal' ? 'bg-primary text-white' : '']"
              @click="selectTab('sum-withdrawal')"
            >Sum withdrawal</button>
          </li>
        </ul>
        <template v-if="data">
          <div class="row" v-if="currentTab == 'count-payout'">
            <div
              v-for="(metric, index) in data.countPayout"
              :key="index"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            >
              <div
                class="card"
                :class="{
                  'blue':  index == 0,
                  'green':  index == 1,
                  'orange':  index == 2,
                  'red':  index == 3,
                  'purple':  index == 4,
                }"
              >
                <div class="value">{{metric.currencyCode }}</div>
                <!-- <i class="zmdi zmdi-upload"></i> -->
                <div class>{{ metric.currencyName }}</div>
                <div class="value">{{ metric.totalAmount }}</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="currentTab == 'count-withdrawal'">
            <div
              v-for="(metric, index) in data.countWithdrawer"
              :key="index"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            >
              <div
                class="card"
                :class="{
                  'blue':  index == 0,
                  'green':  index == 1,
                  'orange':  index == 2,
                  'red':  index == 3,
                  'purple':  index == 4,
                }"
              >
                <div class="value">{{metric.currencyCode }}</div>
                <!-- <i class="zmdi zmdi-upload"></i> -->
                <div class>{{ metric.currencyName }}</div>
                <div class="value">{{ metric.totalAmount }}</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="currentTab == 'sum-payout'">
            <div
              v-for="(metric, index) in data.sumPayout"
              :key="index"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            >
              <div
                class="card"
                :class="{
                  'blue':  index == 0,
                  'green':  index == 1,
                  'orange':  index == 2,
                  'red':  index == 3,
                  'purple':  index == 4,
                }"
              >
                <div class="value">{{metric.currencyCode }}</div>
                <!-- <i class="zmdi zmdi-upload"></i> -->
                <div class>{{ metric.currencyName }}</div>
                <div class="value">{{ metric.totalAmount }}</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="currentTab == 'sum-withdrawal'">
            <div
              v-for="(metric, index) in data.sumWithdrawer"
              :key="index"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            >
              <div
                class="card"
                :class="{
                  'blue':  index == 0,
                  'green':  index == 1,
                  'orange':  index == 2,
                  'red':  index == 3,
                  'purple':  index == 4,
                }"
              >
                <div class="value">{{metric.currencyCode }}</div>
                <!-- <i class="zmdi zmdi-upload"></i> -->
                <div class>{{ metric.currencyName }}</div>
                <div class="value">{{ metric.totalAmount }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="error" class="display-2-lg">{{ error.message }}</div>
          <div v-else class="display-2">...</div>
        </template>
      </div>
    </div>
  </HourglassLoader>
</template>
  
  <script>
import { onBeforeMount, ref } from "vue";
import { apiGet } from "@/api";
import HourglassLoader from "@/components/loader/HourglassLoader";
import { Toast } from "@/utils/notify";

export default {
  name: "Metrics",
  components: { HourglassLoader },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const error = ref(null);
    const onlinePaymentRef = ref(null);
    const REF_paymentModalButton = ref(null);

    const fromDate = ref("");

    const toDate = ref("");

    const params = ref(new URLSearchParams());

    const currentTab = ref("count-payout");

    const selectTab = (tab) => {
      currentTab.value = tab;
    };

    // const updateParams = () => {
    //   params.value.delete("startDate");
    //   params.value.delete("endDate");

    //   if (fromDate.value) {
    //     params.value.append("startDate", fromDate.value);
    //   }
    //   if (toDate.value) {
    //     params.value.append("endDate", toDate.value);
    //   }
    // };

    const clearFilter = () => {
      fromDate.value = "";
      toDate.value = "";
      params.value = new URLSearchParams();
      RefreshBalance();
    };

    const RefreshBalance = async () => {
      loading.value = true;

      params.value = new URLSearchParams();

      if (fromDate.value) params.value.append("startDate", fromDate.value);
      if (toDate.value) params.value.append("endDate", toDate.value);

      try {
        const response = await apiGet(
          `/admin/metrics/daily-metrics?${params.value.toString()}`
        );
        // const response = await apiGet(`/admin/metrics/daily-metrics?startDate=2023-04-03&endDate=2023-06-05`);
        data.value = response.data.data;

        console.log(data.value);

        Toast.fire({
          icon: "success",
          title: "Metrics Fetched",
        }).then();
      } catch (e) {
        error.value = e;
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch wallet balance: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshBalance);

    return {
      clearFilter,
      fromDate,
      toDate,
      params,

      RefreshBalance,
      data,
      error,
      loading,
      onlinePaymentRef,
      REF_paymentModalButton,
      selectTab,
      currentTab,
    };
  },
};
</script>
  
  <style scoped lang="scss">
.card {
  border-radius: 8px;
  color: white;
  padding: 10px;
  position: relative;

  .btn:focus {
    outline: none !important;
  }

  .zmdi {
    color: white;
    font-size: 28px;
    opacity: 0.3;
    position: absolute;
    right: 13px;
    top: 13px;
  }

  .stat {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 8px;
    margin-top: 25px;
    padding: 10px 10px 0;
    text-transform: uppercase;
  }

  .title {
    display: inline-block;
    font-size: 14px;
    padding: 10px 10px 0;
    text-transform: uppercase;
  }

  .value {
    font-size: 28px;
    padding: 0 10px;
  }

  &.blue {
    background-color: #2298f1;
  }

  &.green {
    background-color: #66b92e;
  }

  &.orange {
    background-color: #da932c;
  }

  &.red {
    background-color: #d65b4a;
  }
  &.purple {
    background-color: #b417b4;
  }
}
</style>
  