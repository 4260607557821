
import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "UserRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.clearance < 3 ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.clearance = props.dt.clearance < 3 ? 3 : 1;
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ToggleBan = async () => {
      loading.value = true;
      try {
        await apiPost(`/admin/user-account/toggle-ban/${props.dt.id}`, {
          userId: props.dt.id,
        });
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.is_banned = !props.dt.is_banned;
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      ToggleAdmin,
      ToggleBan,
    };
  },
});
