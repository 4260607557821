<template>
  <section>
    <div class="row justify-content-md-center mt-4">
      <div class="col-12 mb-4">
        <Metrics />
      </div>
      <div class="col-12 mb-4">
        <UserWithBalance />
      </div>
      <!-- <section v-show="false">
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                    <span class="fas fa-credit-card"></span>
                  </div>
                </div>
                <div class="col">
                  <div class>
                    <h2 class="h5">Vouchers</h2>
                    <h3 class="mb-1">0</h3>
                  </div>
                  <div class="small mt-2">
                    <router-link to="/u/voucher/ordered">Go to voucher</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                    <span class="fas fa-hand-holding-usd"></span>
                  </div>
                </div>
                <div class="col">
                  <div class>
                    <h2 class="h5">Paycode</h2>
                    <h3 class="mb-1">0</h3>
                  </div>
                  <div class="small mt-2">
                    <router-link to="/u/paycode/created">Go to paycode</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                    <span class="fas fa-hands"></span>
                  </div>
                </div>
                <div class="col">
                  <div class>
                    <h2 class="h5">Masspayout</h2>
                    <h3 class="mb-1">0</h3>
                  </div>
                  <div class="small mt-2">
                    <router-link to="/u/masspayout/created">Go to Masspayout</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>-->
    </div>
  </section>
</template>
  
  <script lang="ts">
import { defineComponent } from "vue";
import Metrics from "@/views/audit/constituents/AuditMetrics.vue";
import UserWithBalance from "./constituents/UserWithBalance.vue";
export default defineComponent({
  name: "Financial Audits",
  components: {
    Metrics,
    UserWithBalance,
  },
});
</script>
  
  <style scoped></style>
  