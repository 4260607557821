
import { defineComponent } from "vue";
import Metrics from "@/views/audit/constituents/AuditMetrics.vue";
import UserWithBalance from "./constituents/UserWithBalance.vue";
export default defineComponent({
  name: "Financial Audits",
  components: {
    Metrics,
    UserWithBalance,
  },
});
