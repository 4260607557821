<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <router-link :to="`/users/${dt.user_id}`" class="font-weight-bold">{{ dt.user_id }}</router-link>
      </td>
      <!-- <td>
        <span class="font-weight-normal">{{ dt.Profile?.first_name }} {{ dt.Profile?.last_name }}</span>
      </td>-->
      <td>
        <span class="font-weight-normal">{{ dt.User.username }}</span>
      </td>
      <td>
        <router-link
          :to="`/users/${dt.user_id}`"
          class="font-weight-normal text-primary"
        >{{ dt.User.email }}</router-link>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.balance }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.is_banned ? "YES" : "NO" }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.User.clearance }}</span>
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link :to="`/users/${dt.user_id}`" class="dropdown-item text-dark">
              <span class="fas fa-eye mr-1"></span>
              See Details
            </router-link>
            <a
              v-admin="100"
              href="#"
              class="dropdown-item text-danger"
              :class="dt.is_banned ? 'text-danger' : 'text-dark'"
              @click="
                  RequestConfirmation(
                    `Are you sure you want to ${
                      dt.is_banned ? 'unban' : 'ban'
                    } this user?`,
                    ToggleBan
                  )
                "
            >
              <template v-if="dt.is_banned">
                <span class="fas fa-times mr-1"></span>
                Lift Ban
              </template>
              <template v-else>
                <span class="fas fa-check mr-1"></span>
                Ban User
              </template>
            </a>
            <a
              v-admin="100"
              class="dropdown-item"
              :class="dt.clearance < 3 ? 'text-danger' : 'text-dark'"
              @click="
                  RequestConfirmation(
                    dt.clearance < 3
                      ? 'Do you really want to give this user admin access'
                      : 'Are you sure to remove admin access from this user',
                    ToggleAdmin
                  )
                "
            >
              <span class="fas fa-user-cog mr-1"></span>
              <span v-if="dt.clearance < 3">Make Admin</span>
              <span v-else>Remove Admin</span>
            </a>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>
  
  <script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "UserRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.clearance < 3 ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.clearance = props.dt.clearance < 3 ? 3 : 1;
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ToggleBan = async () => {
      loading.value = true;
      try {
        await apiPost(`/admin/user-account/toggle-ban/${props.dt.id}`, {
          userId: props.dt.id,
        });
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.is_banned = !props.dt.is_banned;
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      ToggleAdmin,
      ToggleBan,
    };
  },
});
</script>
  
  <style scoped></style>
  