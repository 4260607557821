<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-1">
        <div class="d-block">
          <h2 class="h4">Users with Balance</h2>
        </div>

        <div class="d-flex align-content-center">
          <div>
            <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
          </div>
          <div v-if="data?.meta" class="table-settings mb-1">
            <div class="row align-items-center justify-content-between">
              <div class="col-12 pl-md-0">
                <div class="d-flex justify-content-end align-items-center py-1">
                  <CountSelector class="me-2" v-model="data.meta.per_page" @updated="RefreshData" />
                  <DataFilterSelector v-model="type" :filters="filters" @updated="RefreshData" />
                  <!-- <div></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ul class="nav nav-tabs mb-2">
          <li class="nav-item m-2" v-for="currency in currencies" :key="currency.id">
            <button
              :class="['btn btn-secondary', currentTab == currency.id ? 'bg-primary text-white' : '']"
              @click="selectTab(currency.id)"
            >{{ currency?.code }}</button>
          </li>

          <!-- <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'count-withdrawal' ? 'bg-primary text-white' : '']"
              @click="selectTab('count-withdrawal')"
            >NGN</button>
          </li>
          <li class="nav-item m-2">
            <button
              :class="['btn btn-secondary', currentTab == 'sum-payout' ? 'bg-primary text-white' : '']"
              @click="selectTab('sum-payout')"
            >GHS</button>
          </li>-->
        </ul>
      </div>

      <div v-if="data?.totalUserBalance">
        <p class="text-gray text-right">Total Balance</p>
        <div class="text-right mx-2">
          <div class="d-lg-flex flex-row justify-content-end align-items-center py-1">
            <h5 class="fw-bolder text-info border border-r ms-3 p-3">
              <!-- <span class="mr-3">{{data?.totalUserBalance?.currencyCode}}</span> -->
              {{ new Intl.NumberFormat(`en-${data?.totalUserBalance?.currencyCode == 'NGN' ? 'NG' : data?.totalUserBalance?.currencyCode == 'GHS' ? 'GH' : data?.totalUserBalance?.currencyCode == 'USD' ? 'US' : ''}`, { style: 'currency', currency: `${data.totalUserBalance?.currencyCode}`, minimumFractionDigits: 4,
              maximumFractionDigits: 4, }).format(data?.totalUserBalance?.totalBalance ) }}
              <!-- {{ data?.totalUserBalance?.totalBalance }} -->
            </h5>
          </div>
        </div>
      </div>
      <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
        <table class="table table-hover min-h-400">
          <thead>
            <tr>
              <th>#</th>
              <!-- <th>Name</th> -->
              <th>Username</th>
              <th>Email</th>
              <th>Balance</th>
              <th>Is Banned</th>
              <th>Clearance</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="data">
            <UserRow v-for="(dt, index) in data.userLists" :key="index" :dt="dt" />
          </tbody>
        </table>
        <Pagination
          v-if="data"
          v-model="data.meta.page"
          :currentPage="data.meta.page"
          :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page"
          @updated="RefreshData"
        />
      </div>
    </section>
  </HourglassLoader>
</template>
  
  <script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";

import UserRow from "./UserWithBalanceRow.vue";
import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "RegisteredForMasspayoutID",
  components: {
    DataFilterSelector,
    UserRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const filters = {
      0: "All",
      100: "Clearance 100",
      3: "Clearance 3",
      1: "Clearance 1",
    };
    const type = ref(undefined);

    const route = useRoute();

    const currencies = ref([]);

    const currentTab = ref(1);
    const selectTab = (id: number) => {
      currentTab.value = id;
      RefreshData();
    };

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/metrics/users-with-balance?currencyId=${currentTab.value}`,
          Object.assign((data.value as any)?.meta ?? {}, { type: type.value })
        );
        const getCurrencyResponse = await apiGet("/misc/fiat-currencies");

        data.value = response.data.data;
        currencies.value = getCurrencyResponse.data.data.fiat;
        Toast.fire({
          icon: "success",
          title: "Users Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      selectTab,
      currencies,
      currentTab,

      route,
      data,
      loading,
      filters,
      type,
    };
  },
});
</script>
  
  <style scoped></style>
  